import { create } from 'zustand';

/**
 * Timeout duration for toasts in milliseconds.
 * @type {number}
 */
const TOAST_TIMEOUT = 7000; // 1000 = 1 second

/**
 * Zustand store for managing toast state.
 * @type {import("zustand").UseStore<ToastState>}
 */
const useToastStore = create((set, get) => ({
  /**
   * The last assigned ID for a toast.
   * @type {number}
   */
  lastId: 0,

  /**
   * Array of toast objects.
   * @type {Toast[]}
   */
  toasts: [],

  /**
   * Adds a new toast to the state.
   * @param {Omit<Toast, "id" | "timeoutId">} toast - The toast object to add.
   * @returns {void}
   */
  add: (toast) => {
    get().addMulti([toast]);
  },

  addMulti: (toasts) => {
    if (!toasts?.length) return;
    let lastId = get().lastId;
    const newToasts = toasts
      .filter((t) => t.title)
      .map((t) => {
        // This is important for the closure of the setTimeout
        const id = lastId++;
        const timeoutId = setTimeout(() => {
          get().remove(id);
        }, TOAST_TIMEOUT);
        return { ...t, id, timeoutId };
      });

    set((state) => ({
      toasts: [...state.toasts, ...newToasts],
      lastId: lastId,
    }));
  },

  /**
   * Removes a toast from the state.
   * @param {number} id - The ID of the toast to remove.
   * @returns {void}
   */
  remove: (id) => {
    const timeoutId = get().toasts.find((t) => t.id === id)?.timeoutId;
    clearTimeout(timeoutId);

    set((state) => ({
      toasts: state.toasts.filter((t) => t.id !== id),
    }));
  },
}));

/**
 * Variant options for toast messages.
 * @typedef {"success" | "error" | "warn" | "no-icon"} ToastVariant
 */

/**
 * Represents a toast message.
 * @typedef {Object} Toast
 * @property {number} id - The unique identifier of the toast.
 * @property {string} title - The title of the toast.
 * @property {string | undefined} description - The optional description of the toast.
 * @property {ToastVariant | undefined} variant - The variant type of the toast.
 * @property {NodeJS.Timeout} timeoutId - The timeout ID associated with the toast.
 */

/**
 * Represents the state of the toast store.
 * @typedef {Object} ToastState
 * @property {number} lastId - The last assigned ID for a toast.
 * @property {Toast[]} toasts - Array of toast objects.
 * @property {(toast: Omit<Toast, "id" | "timeoutId">) => void} add - Adds a new toast to the state.
 * @property {(id: number) => void} remove - Removes a toast from the state.
 */

export default useToastStore;
