import useToastStore from '../../../JSX/general/ToastSystem/ToastStore/index.js';

export class ApiInterceptorService {
  constructor() {
    this.interceptor = null;
    this.shouldHandleRedirect = true;
    this.shouldDisplayError = true;
    this.shouldDisplaySuccess = true;
  }

  setInterceptor(
    interceptor,
    shouldHandleRedirect,
    shouldDisplayError = true,
    shouldDisplaySuccess = true,
    options,
  ) {
    this.shouldDisplayError = shouldDisplayError;
    this.shouldDisplaySuccess = shouldDisplaySuccess;
  }

  async onSuccess(response) {
    const { error_messages, success_messages } = this.getMessages(response);
    const addToasts = useToastStore.getState().addMulti;

    if (this.shouldDisplaySuccess) {
      addToasts(success_messages.map((m) => ({ title: m, variant: 'success' })));
    }
    if (this.shouldDisplayError) {
      addToasts(error_messages.map((m) => ({ title: m, variant: 'error' })));
    }

    return response;
  }

  async onError(error) {
    const response = error.response;
    const { error_messages } = this.getMessages(response);
    const addToasts = useToastStore.getState().addMulti;

    if (this.shouldDisplayError) {
      addToasts(error_messages.map((m) => ({ title: m, variant: 'error' })));
    }
  }

  /**
   * This handle the all the different schema of v2, v3 and v3b (what meir "weird v3")
   */
  getMessages(response) {
    const body = response?.data;
    let success_messages = [];
    let error_messages = [];

    if (Array.isArray(body?.response?.success_messages)) {
      success_messages = body.response.success_messages;
    } else if (Array.isArray(body?.data?.success_messages)) {
      success_messages = body.data.success_messages;
    } else if (Array.isArray(body?.success_messages)) {
      success_messages = body.success_messages;
    }

    if (Array.isArray(body?.response?.error_messages)) {
      error_messages = body.response.error_messages;
    } else if (Array.isArray(body?.data?.error_messages)) {
      error_messages = body.data.error_messages;
    } else if (Array.isArray(body?.error_messages)) {
      error_messages = body.error_messages;
    }

    return { success_messages, error_messages };
  }
}
