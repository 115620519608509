import * as Sentry from "@sentry/react";

// ENV is provided by webpack and is set to 'production' or 'development' or 'qa' based on the build
export function initSentry() {
    Sentry.init({
        dsn: "https://14415b77d00fb04be716164747cf415b@o4506688486768640.ingest.sentry.io/4506690246737920",
        //INFO: THIS VARIABLE IS GENERATED BY THE WEBPACK PLUGIN
        //eslint-disable-next-line
        environment: ENV,
        //enabled sentry for qa and production
        //eslint-disable-next-line
        enabled: ENV !== 'development',
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^\//],
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                //eslint-disable-next-line
                "^https:\/\/(?:\w+\.)?(?:[^\/]+)\.(?:\w{2,})(?:\/api)?\/(?:v2|api\/v3)$"
            ],
            networkRequestHeaders: ['X-Custom-Header','x-device-id', 'Authorization', 'Content-Type', 'User-Agent', 'Accept'],
            networkResponseHeaders: ['X-Custom-Header', 'Content-Type'],
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });

      setUserFromGlobal();
}

export function interceptorSentryAxios(axiosInstance) {
    axiosInstance.interceptors.request.use(
        (config) => {
            Sentry.addBreadcrumb({
                category: "axios",
                message: "Request",
                data: {
                    url: config.url,
                    method: config.method,
                    headers: config.headers,
                    body: config.data, // You can capture the request body here
                    params: config.params,
                },
            });

          return config;
        },
        (error) => {
            const axiosError = {
                message: error.message,
                stack: error.stack, // The error stack trace
            };

            Sentry.addBreadcrumb({
                category: "axios",
                message: "Request Error",
                data: axiosError,
            });

            // Capture the detailed Axios error with additional data
            Sentry.withScope((scope) => {
                scope.setExtra("axiosError", axiosError);
                scope.captureException(error);
            });
              
            return Promise.reject(error);
        }
      );
      
      // Response interceptor
    axiosInstance.interceptors.response.use(
        (response) => {
            Sentry.addBreadcrumb({
                category: "axios",
                message: "Response",
                data: {
                    status: response.status,
                    body: response.data, // You can capture the response body here
                },
            });
    
            return response;
        },
        (error) => {    
            const data = error.response?.data?.data || error.response?.data;

            const axiosError = {
                message: error.message,
                stack: error.stack, // The error stack trace // You can capture the response body here
                status: error.response?.status,
            }
            
            Sentry.withScope((scope) => {
                scope.setExtra("axiosError", axiosError);
                scope.setExtra("axiosDataError", data);
                scope.captureException(error);
            });

            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

export function setUserFromGlobal() {
    const client = globalThis?.current_client;
    if (client) {
        Sentry.setUser({
            id: client.id,
            email: client.email,
            name: client.name,
            isAdmin: client.is_admin,
        });
    }
}

export default Sentry;