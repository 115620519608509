import {
  format,
  differenceInDays,
  formatISO,
  differenceInHours,
  differenceInMinutes,
  parse,
  formatDistanceToNowStrict,
} from 'date-fns';
import moment from 'moment';

import smoothscroll from 'smoothscroll-polyfill';

import { ASC, RECAPTCHA_SITE_KEY } from './constants';

// init smoothscroll
window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

import json2csv from 'json2csv';

export const debounce = (func, wait) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const partition = (array, callback) => {
  return array.reduce(
    (acc, cur, i) => {
      callback(cur, i) ? acc[0].push(cur) : acc[1].push(cur);
      return acc;
    },
    [[], []],
  );
};

export const sortByField = (array, field, isCaseSensitive = true) => {
  const newArray = deepCopy(array);
  return newArray.sort((a, b) => {
    const aField = isCaseSensitive ? a[field] : a[field].toLowerCase();
    const bField = isCaseSensitive ? b[field] : b[field].toLowerCase();
    if (aField > bField) return 1;
    if (aField < bField) return -1;
    return 0;
  });
};
export const sortByBooleanField = (array, field = true) => {
  return array.sort((a, b) => b[field] - a[field]);
};

export const sortByNumericField = (array, field, direction = ASC) => {
  return array.sort((a, b) => {
    const aField = Number(a[field]);
    const bField = Number(b[field]);
    return direction === ASC ? aField - bField : bField - aField;
  });
};

export const sortWithDirection = (data, sortBy, sortDirection) => {
  return data.sort((a, b) => {
    const aField = a[sortBy];
    const bField = b[sortBy];
    if (sortDirection === ASC) {
      if (aField > bField) return 1;
      if (aField < bField) return -1;
    } else {
      if (aField < bField) return 1;
      if (aField > bField) return -1;
    }
    return 0;
  });
};

export const sortByDate = (array, field) => {
  return array.sort((a, b) => {
    return moment(b[field]) - moment(a[field]);
  });
};

export const sortByPropertyWithOrder = (array, property, order) => {
  return array.sort((a, b) => {
    return order[a[property]] - order[b[property]];
  });
};

export const capitalize = (str) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addCommas = (number) => {
  if (isNaN(number) || number === null) return number;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addCurrency = (number, isCommas = true) => {
  if (isNaN(number) || number === null) return number;

  return isCommas ? `$${addCommas(number)}` : `$${number}`;
};

export const hideKB = () => {
  var field = document.createElement('input');
  field.setAttribute('type', 'text');
  field.style.position = 'absolute';
  field.style.left = '-10000px';
  field.style.top = '-10000px';
  document.body.appendChild(field);
  setTimeout(function () {
    field.focus();
    setTimeout(function () {
      field.setAttribute('style', 'display:none;');
      document.body.removeChild(field);
    }, 50);
  }, 50);
};

export let debounceArray = (wait) => {
  let timeout;
  let funcMain;

  let funcRun = function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      funcMain.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  return {
    add: (func) => {
      funcMain = func;
      return funcRun;
    },
  };
};

export const omit = (obj, keys) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));

export const exportToCsv = (filename, data, defaultHeader) => {
  try {
    const csv = data.length ? json2csv.parse(data) : defaultHeader;
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.csv`;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100);
  } catch (error) {
    if (error) console.error(error);
    return;
  }
};

export const dismissKeyboard = (e) => {
  if (e.key === 'Enter') {
    e.target.blur();
  }
};

export const redirectTo = (redirect_url) => {
  if (redirect_url) {
    window.location.href = redirect_url;
  }
};

export function build_query_string(url, qs) {
  var opr = '';
  opr = url.indexOf('?') == -1 ? '?' : '&';
  return url + opr + qs;
}

export function getParameters(url) {
  const url_object = new URL(url);
  let search_string = url_object.search.substr(1);
  search_string = search_string.replace('?', '&');
  const params = search_string.split('&');
  return params;
}

export const getQSParams = () => {
  //copied from all,jss
  let qs = window.location.search.split('+').join(' ');
  let params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }
  return params;
};

export function sortByFieldDesc(a, b, fieldName) {
  if (a[fieldName] > b[fieldName]) {
    return -1;
  } else if (a[fieldName] < b[fieldName]) {
    return 1;
  } else {
    return 0;
  }
}

export const joinByConditions = (classes = []) => {
  return classes
    .filter((cls) => cls.condition)
    .map((cls) => cls.text)
    .join(' ');
};

export const addZero = (num) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const getMultiStringArray = (value, splitChar) => {
  return value
    ?.split(splitChar)
    ?.map((value) => value.trim())
    ?.filter((value) => value.length);
};

export const randomWithRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const removeBase64Prefix = (prefix) => {
  const regex = /data:image\/.*;base64,/g;
  const stripped = prefix.replace(regex, '');
  return stripped;
};

export const calculatePercentage = (part, total) => {
  return Math.round((part / total) * 100);
};

export const formatDateMonthDay = (date) => {
  return format(date, 'MMM dd');
};

export const formatWithMoment = (date, format) => {
  return moment(date).format(format);
};

export const addDeltaDate = (date, duration) => {
  return moment(date).add(duration);
};

export const addDays = (days, date = new Date()) => {
  return moment(date).add(days, 'days').toDate();
};

export const formatToIsoDate = (date) => {
  return formatISO(date, {
    representation: 'date',
  });
};

export const calculateDateDifference = (date) => {
  const currentDate = new Date();
  const otherDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  return differenceInDays(currentDate, otherDate);
};

export const calculateDetailedDateDifference = (timestamp) => {
  let givenDateUtc = new Date(timestamp);
  let currenDateUtc = new Date();
  let days = differenceInDays(currenDateUtc, givenDateUtc);
  if (days < 1) {
    let difference = '';
    const hours = differenceInHours(currenDateUtc, givenDateUtc);
    const minutes = differenceInMinutes(currenDateUtc, givenDateUtc) - hours * 60;
    hours && hours > 0 && (difference += `${hours} hour${hours > 1 ? 's' : ''}`);
    minutes && minutes > 0 && (difference += `${hours ? ', ' : ''}${minutes} min`);
    return difference;
  }
  return formatDistanceToNowStrict(givenDateUtc);
};

export const convertSvgToPng = (svgFile) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      const svgData = event.target.result;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        canvas.toBlob((blob) => {
          const file = new File([blob], svgFile.name.replace('.svg', '.png'), {
            type: 'image/png',
          });
          resolve(file);
        }, 'image/png');
      };

      image.onerror = (error) => {
        reject(error);
      };

      const encoder = new TextEncoder();
      const svgDataEncoded = encoder.encode(svgData);
      const base64EncodedData = btoa(String.fromCharCode(...new Uint8Array(svgDataEncoded)));

      image.src = 'data:image/svg+xml;base64,' + base64EncodedData;
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(svgFile);
  });
};

export const addUnique = (value, list) => {
  const isExist = list.find((item) => item === value);
  if (isExist) return list;
  return [...list, value];
};

export const getKeyValueFromSearchUrl = (searchUrl, key) => {
  const searchParams = new URLSearchParams(searchUrl);
  return searchParams.get(key);
};

// format number in (xxx) xxx-xxxx format
export const formatPhone = (num) => {
  if (!num) return num;

  return '(' + num.substring(0, 3) + ') ' + num.substring(6, 3) + '-' + num.substring(6);
};
export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const formatPhoneNumber = (value) => {
  if (!value) return;

  const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : value;
};

export const updateMinimalUiMeta = (action) => {
  const viewportMetaTag = document.querySelector('meta[name=viewport]');

  if (viewportMetaTag) {
    let contentValue = viewportMetaTag.getAttribute('content');

    if (action === 'add' && !contentValue.includes('user-scalable=yes')) {
      contentValue += ', user-scalable=yes';
    } else if (action === 'remove' && contentValue.includes('user-scalable=yes')) {
      contentValue = contentValue.replace(/,\s*user-scalable=yes/, '');
    }

    viewportMetaTag.setAttribute('content', contentValue);
  }
};

export const replaceSpaceWithUnderscore = (str) => str.replace(/\s/g, '_');

export const upperCaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const scrollOnElementId = (id, position) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo(0, position);
  }
};

export const deepCopy = (obj) => {
  if (!obj) return undefined;
  return JSON.parse(JSON.stringify(obj));
};

export const daysToMonths = (daysNum) => {
  const startDate = new Date();
  const endDate = new Date(new Date().setDate(startDate.getDate() - daysNum));

  const monthDiff = startDate.getMonth() - endDate.getMonth();
  const yearDiff = startDate.getYear() - endDate.getYear();

  return monthDiff + yearDiff * 12;
};

export const stringSingluarOrPlural = ({ count, singular, plural }) => {
  return count === 1 ? singular : plural;
};

export const numberToCurrency = (num) => {
  if (num == undefined || num == null) return;
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatExpirationDate = (value) => {
  return value
    .replace(/[^0-9/]/g, '')
    .replace(/\//g, '')
    .match(/.{1,2}/g)
    ?.join('/');
};
export const truncate = (str, num) => {
  const newStr = str.split('', num).join('');
  return str.length >= num ? newStr + '...' : newStr;
};

export function removeQueryParamFromUrl(url, paramToRemove) {
  const urlObj = new URL(url);

  if (!urlObj.searchParams.has(paramToRemove)) {
    return;
  }

  urlObj.searchParams.delete(paramToRemove);

  const updatedUrl = urlObj.toString();
  window.history.replaceState({}, '', updatedUrl);
}

export const scrollToEndPageSmoothly = () => {
  window.scroll({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
};

export const scrollToTopPageSmoothly = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

export const urlToFile = async ({ url, filename, mimeType }) => {
  const response = await fetch(url);
  const data = await response.blob();
  const file = new File([data], filename, { type: mimeType });
  return file;
};

export const attachFileToCallback = ({ accept, callback }) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = accept;
  input.onchange = (e) => {
    callback(e);
    document.body.removeChild(input);
  };
  document.body.appendChild(input);
  input.click();
};

export const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const openUrlInNewTab = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const fetchRecaptchaToken = async () => {
  if (!window.grecaptcha || !RECAPTCHA_SITE_KEY) return null;
  try {
    return await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
  } catch (error) {
    return null;
  }
};

export const applyYearTransformation = (year2digit) => {
  const currentYear = new Date().getFullYear();
  const currentCentury = Math.floor(currentYear / 100) * 100;
  return parseInt(year2digit) + currentCentury;
};

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const formatDate = (date, formatType = 'MM-dd-yyyy') => {
  return format(new Date(date), formatType);
};

export const detectHistoryBack = () => {
  return window.performance?.getEntriesByType('navigation')?.[0]?.type === 'back_forward';
};

export const getRandomArrValue = (arr) => {
  const index = getRandomArrIndex(arr);
  return index !== null ? arr[index]: null;
};

export const getRandomArrIndex = (arr) => {
  if (arr.length === 0) return null;

  const randomIndex = Math.floor(Math.random() * arr.length);
  return randomIndex;
};

export function getInitials(name) {
  return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
}

function isDefinedWindow(v) {
  return typeof window[v] !== 'undefined';
}

export function useABTest(v) {
  return isDefinedWindow(v) ? window[v] : false;
}
