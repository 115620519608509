import axios from 'axios';

import { ApiInterceptorService } from './ApiInterceptorService.js';
import { interceptorSentryAxios } from '../../../JSX/utils/sentry/index.js';

const axiosInstance = interceptorSentryAxios(axios);

export const mapToReturnData = (response) => {
  return response?.data?.response?.return_data;
};

export const mapToErrors = (response) => {
  return response?.data?.response?.error_messages || [];
};

class ApiService {
  constructor() {
    this.pendingRequests = new Map();
  }

  getBaseUrl(version = 'v2') {
    return `/api/${version}/`;
  }
  getHeaders(headers) {
    headers['X-NX-PLATFORM'] = 'web';
    return headers;
  }

  async sendClientRequest(operation, route, data = {}, options = {}, version = 'v2') {
    let clientRoute = 'clients/' + window.client_id;
    if (route) clientRoute += '/' + route;
    return this.request(operation, clientRoute, data, options, version);
  }

  addParams(config, params, headers) {
    config.params = params;
    config.headers = headers;
    return config;
  }

  async callApi(operation, route, data = {}, options = {}, version) {
    const apiInterceptorService = new ApiInterceptorService();
    const {
      headers = {},
      moreConfig = {},
      shouldDisplayError = true,
      shouldDisplaySuccess = true,
    } = options;
    const apiHeaders = this.getHeaders(headers);
    apiInterceptorService.setInterceptor(
      this.interceptor,
      null,
      shouldDisplayError,
      shouldDisplaySuccess,
      options,
    );
    const url = this.getBaseUrl(version) + route;
    let res;
    try {
      switch (operation) {
        case 'get':
          res = await this.apiGet(url, data, apiHeaders, moreConfig);
          break;
        case 'post':
          res = await this.apiPost(url, data, apiHeaders, moreConfig);
          break;
        case 'put':
          res = await this.apiPut(url, data, apiHeaders, moreConfig);
          break;
        case 'delete':
          res = await this.apiDelete(url, apiHeaders, moreConfig);
          break;
        default:
          return new Promise((resolve, reject) => {
            reject('Invalid Api Operation');
          });
      }
      apiInterceptorService.onSuccess(res, options);
    } catch (err) {
      apiInterceptorService.onError(err, options);
      throw err;
    }
    return res;
  }

  async request(operation, route, data = {}, options = {}, version) {
    const url = this.getBaseUrl(version) + route;

    const requestKey = JSON.stringify({ method: operation, url, data });

    if (this.pendingRequests.has(requestKey)) {
      const resPromise = this.pendingRequests.get(requestKey);
      return await resPromise;
    }

    try {
      const resPromise = this.callApi(operation, route, data, options, version);
      this.pendingRequests.set(requestKey, resPromise);
      const res = await resPromise;
      return res;
    } finally {
      this.pendingRequests.delete(requestKey);
    }
  }

  getRequestConfig(headers, params, moreConfig) {
    let config = {};
    !!headers && (config.headers = headers);
    !!params && (config.params = params);
    config = { ...config, ...moreConfig };
    return config;
  }

  async apiGet(url, data, headers = {}, moreConfig = {}) {
    const config = this.getRequestConfig(headers, data, moreConfig);
    return await axiosInstance.get(url, config);
  }

  async apiPost(url, data, headers, moreConfig = {}) {
    const config = this.getRequestConfig(headers, null, moreConfig);
    return axiosInstance.post(url, data, config);
  }

  async apiPut(url, data, headers, moreConfig = {}) {
    const config = this.getRequestConfig(headers, null, moreConfig);
    return axiosInstance.put(url, data, config);
  }

  async apiDelete(url, headers, moreConfig = {}) {
    const config = this.getRequestConfig(headers, null, moreConfig);
    return axiosInstance.delete(url, config);
  }

  wrapPromise(promise) {
    let status = 'pending';
    let result;
    let suspend = promise.then(
      (res) => {
        status = 'success';
        result = res;
      },
      (err) => {
        status = 'error';
        result = err;
      },
    );

    const read = () => {
      switch (status) {
        case 'pending':
          throw suspend;
        case 'error':
          throw result;
        case 'success':
          return result;
      }
    };

    return { read };
  }
}

export const apiService = new ApiService();
