export const RIGHT = 'right';
export const LEFT = 'left';
export const ASC = 'asc';
export const DEC = 'dec';
export const DESC = 'desc';
export const EST = 'America/New_York';

export const RADIUS_MIN = 10;
export const RADIUS_MAX = 100;
export const ZIP_LENGTH = 5;

export const MAP_RADIUS_ERROR = 'Radius must be between 10 and 100 miles';
export const MAP_ZIP_ERROR = 'Please enter a valid zip code';
export const CATEGORIES_ERROR = 'Please select one or more categories';
export const RECAPTCHA_SITE_KEY = window.RECAPTCHA_SITE_KEY;

export const TIMES_IN_MS = {
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
};
